import axios from "axios";

const state = {
    pageTitle:'ProjectLink',
    infoDrawer: false,
    dashboardCardsInformation: [
        {
            pl_dashboard_name: 'favourites',
            icon: 'mdi-star',
            name:'Favourites/ Recent',
            description:'This includes a list of your favourite ICRT projects - indicated by a star - or your most recently visited projects. This includes project favourites from Media Gallery and soon to be from Product Tracker.',
        },
        {
            pl_dashboard_name: 'latest_posts',
            icon: 'mdi-forum',
            name:'Latest Forum Posts',
            description:'This includes a list of the latest posts from the Topic or Project forums that you are subscribed to.',
        },
        {
            pl_dashboard_name: 'new_projects',
            icon: 'mdi-briefcase-plus-outline',
            name:'New Projects',
            description:'This contains a list of new projects in topic areas that you are subscribed to.',
        },
        {
            pl_dashboard_name: 'upcoming_key_dates',
            icon: 'mdi-calendar-alert',
            name:'Upcoming Key Dates',
            description:'This contains upcoming key dates for projects or topic areas that you are subscribed to.',
        },
        {
            pl_dashboard_name: 'last_activities',
            icon: 'mdi-history',
            name:'Last Activities',
            description:'This includes a list of the latest activities on the projects you are directly involved in or subscribed to.',
        }
],
    logo:'pl.png',
    navItems:[
        { title: 'Home', icon: 'mdi-view-dashboard', path:'/', name: 'ProjectLink', tooltip: 'Home' },
        { title: 'Projects', icon: 'mdi-table', path:'/pl/list/projects/my', name: 'Projects', tooltip: 'Projects'},
        { title: 'Members', icon: 'mdi-account-group', path:'/pl/members', name: 'Members' , tooltip: 'Members'},
        { title: 'Groups', icon: 'mdi-lightbulb-on-outline', path:'/pl/groups', name: 'Groups', tooltip: 'Groups' },
        { title: 'Labs', icon: 'mdi-flask-empty', path:'/pl/labs', name: 'Labs', tooltip: 'Labs' },
        { title: 'Reports', icon: 'mdi-chart-box-multiple-outline', path:'/pl/reports', name: 'Reports', tooltip: 'Reports' },
        { title: 'KnowHub', icon: 'mdi-folder-multiple-outline', path:'/pl/knowledgeHub', name: 'KnowledgeHub', tooltip: 'KnowledgeHub' },

    ],
    icrtServicesList:[
        {
            icon:'projectlink',
            name: ["Project", "Link"],
            to: 'https://projectlink.international-testing.org/'
        },
        {
            icon: 'producttracker',
            name: ["Product", "Tracker"],
            to: 'https://producttracker.org/'
        },
        {
            icon: 'mediagallery',
            name: ["Media", "Gallery"],
            to: 'https://media.international-testing.org/'
        },
        {
            icon: 'accessfinancials',
            name: ["Access", "Financials"],
            to: 'https://evolutions.accessacloud.com/finops/Homepage.aspx?sid=5d8f4b50cff740afa6a626c7805cd56e'
        },
        {
            icon: 'evaldb',
            name: ["Eval", "DB"],
            to: 'https://evaldb4.international-testing.org/'
        },
        {
            icon: 'knowledgehub',
            name: ["Knowledge", "Hub"],
            to: 'https://projectlink.international-testing.org/pl/knowledgeHub'
        },
        {
            icon: 'api',
            name: ["API", "Gateway"],
            to: 'https://api.icrt.io/'
        }
    ],
    icrtHelp: [
        {
            icon:'mdi-card-account-mail',
            name: 'Contact Us',
            to: '/pl/contact_us',
            newTab: false,
        },
        {
            icon:'mdi-bug',
            name:'Bug Report',
            to: 'mailto:helpdesk@international-testing.org?subject=ProjectLink%20Bug&body=Hello%20ICRT%20Team,%0A%0APlease,%20provide%20us%20with:%0A%0ADescription%20of%20the%20problem%0D%0ALink%20to%20the%20page%20%0D%0AScreenshots%20of%20the%20problem%20',
            newTab: true,
        },
        {
            icon:'mdi-format-list-bulleted',
            name: 'Changes',
            to: '/pl/welcome',
            newTab: false,
        },
        {
            icon:'mdi-multimedia',
            name: 'Video Library',
            to: '/pl/video_library',
            newTab: true,
        },
    ],
    files: {
        docx: {
            icon: 'mdi-file-word',
            color: '#0078D4',
        },
        doc: {
            icon: 'mdi-file-word',
            color: '#0078D4',
        },
        pptx: {
            icon:'mdi-microsoft-powerpoint',
            color: '#A4262C',
        },
        ppt: {
            icon:'mdi-microsoft-powerpoint',
            color: '#A4262C',
        },
        zip: {
            icon: 'mdi-zip-box-outline',
            color: '#0a0a0a',
        },
        pdf: {
            icon: 'mdi-file-pdf-box',
            color: '#A4262C',
        },
        jpg: {
            icon: 'mdi-file-jpg-box',
            color: '#0a0a0a',
         },
        svg: {
            icon: 'mdi-file-image',
            color: '#0a0a0a',
        },
        jpeg: {
            icon: 'mdi-file-jpg-box',
            color: '#0a0a0a',
        },
        webp: {
            icon: 'mdi-file-image',
            color: '#0a0a0a',
        },
        png: {
            icon: 'mdi-file-image',
            color: '#0a0a0a',
        },
        txt: {
            icon: 'mdi-file-document-outline',
            color: '#0a0a0a',
        },
        xls: {
            icon: 'mdi-file-excel',
            color: '#407855',
        },
        xlsx: {
            icon: 'mdi-file-excel',
            color: '#407855',
        },
        xlsb: {
            icon: 'mdi-file-excel',
            color: '#407855',
        },
        xlsm: {
            icon: 'mdi-file-excel',
            color: '#407855',
        },
        xml: {
            icon:  'mdi-xml',
            color: '#bdbdbd',
        },
        eml: {
            icon:  'mdi-email-newsletter',
            color: '#bdbdbd',
        }
    },
    topics: [
        'Mobility', 'HiTech', 'Domestic Appliances', 'Personal Care', 'DIY & Gardening', 'Food and Drink', 'Energy', 'Child Products', 'Leisure', 'Domestic Chemicals', 'Sustainability' , 'Surveys'
    ],
    footerProjectsTests: [
        {
            name: 'Joint Tests',
            to: '/icrt_joint_tests.json',
        },
        {
            name: 'Nucleus Joint tests',
            to: '/nucleus_joint_tests.json',
        },
        {
            name: 'Other Tests',
            to: '/other_tests.json',
        },
        {
            name: 'Asia Pacific Tests',
            to: '/asia_pacific_tests.json',
        },
        {
            name:  'Nordic Tests',
            to: '/nordic_tests.json',
        }
    ],
    countries: null,
    filtersLists: [
        {
            name: 'Joint Tests',
            activator: 'joint_tests',
            options: [
                {
                    name: 'All ICRT Joint Tests',
                    url: '/icrt_joint_tests.json',

                },
                {
                    name: 'Nucleus Joint Tests',
                    url: '/nucleus_joint_tests.json',
                },
                {
                    name: 'Other Tests',
                    url: '/other_tests.json',
                },
                {
                    name: 'Joint Test Proposals',
                    url: '/joint_test_proposals.json',
                },
            ]
        },
        {
            name: 'Market Place',
            activator: 'market_place',
            options: [
                {
                    name: 'Shared Results',
                    url: '/market_place.json',

                },
            ]
        },
        {
            name: 'Regions',
            activator: 'regions',
            options: [
                {
                    name: 'Nordic Tests',
                    url: '/nordic_tests.json',

                },
                {
                    name: 'Asia Pacific Tests',
                    url: '/asia_pacific_tests.json',
                },
                {
                    name: 'Euroconsumers Tests',
                    url: '/euroconsumers_tests.json',
                },
            ]
        },
        {
            name: 'Tests',
            activator: 'tests',
            options: [
                {
                    name: 'All ICRT Tests',
                    url: '/projects.json',

                },
                {
                    name: 'Current Year Tests',
                    url: '/projects.json',
                },
                {
                    name: 'Tests By Organisation',
                    url: '/by_org.json',
                },
                {
                    name: 'National Tests',
                    url: '/national_tests.json',
                },
            ]
        }
    ],
    reportsTypes: [
        {
            name: 'All Active Projects',
            type: 'data',
            path: 'reports/e30=',
        },
        {
            name: 'Asia Pacific Report',
            type: 'prefill',
            path: 'reports/asia_pacific.json',
        },
        {
            name: 'Food Report',
            type: 'prefill',
            path: 'reports/food.json',
        },
        {
            name: 'Full Staff List',
            type: 'data',
            path: 'reports/full/staff_list',
        },
        {
            name: 'Nordic Report',
            type: 'prefill',
            path: 'reports/nordic.json',
        },
        {
            name: 'Nucleus Tests',
            type: 'prefill',
            path: 'reports/nucleus.json',
        },
        {
            name: 'Nucleus Staff List',
            type: 'data',
            path: 'reports/nucleus/staff_list',
        },
        {
            name: 'Personal Care & Medical',
            type: 'prefill',
            path: 'reports/personal_care.json',
        },
        {
            name: 'Price List',
            type: 'data',
            path: 'reports/price_list',
        },
        {
            name: 'Evaluations',
            type: 'data',
            path: 'reports/evaluations',
        }
    ],
}
const getters = {
    getPageTitle: state => state.pageTitle,
    infoDrawer: state => state.infoDrawer,
    dashboardCardsInformation: state => state.dashboardCardsInformation,
    logo: state => state.logo,
    navItems: state => state.navItems,
    icrtServicesList: state => state.icrtServicesList,
    icrtHelp:state => state.icrtHelp,
    files: state => state.files,
    topics: state => state.topics,
    footerProjectsTests: state => state.footerProjectsTests,
    countries: state => state.countries,
    filtersLists: state => state.filtersLists,
    reportsTypes: state => state.reportsTypes,
}
const mutations = {
    setPageTitle: (state, data) => state.pageTitle = data,
    setInfoDrawer: (state, data) => state.infoDrawer = data,
    setCountries: (state, data) => state.countries = data,
}

const actions = {
    changePageTitle({commit}, title){
        commit('setPageTitle', title);
    },

    fetchCountries({commit}){
        axios.get(`/countries.json`).then(res => {
            commit('setCountries', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    }
  }

export default {
    state,
    getters,
    mutations,
    actions,
}
