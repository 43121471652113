import axios from 'axios';

/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    projectStaff: null,
    usersDropdown: null,
    rolesDropdown: null,
    staffScheme: null,
    newStaffParams: {},
    newStaffArray: [],
    editStaffScheme: null,
    staffError: null,
}

/* * * GETTERS * * */
const getters = {
    projectStaff: state => state.projectStaff,
    usersDropdown: state => state.usersDropdown,
    rolesDropdown: state => state.rolesDropdown,

    staffScheme: state => state.staffScheme, //all details staff scheme
    newStaffParams: state => state.newStaffParams,
    newStaffArray: state => state.newStaffArray,
    editStaffScheme: state => state.editStaffScheme,
    staffError: state => state.staffError,

}

/* * * MUTATIONS * * */
const mutations = {
    setProjectStaff: (state, data) => state.projectStaff = data,

    setDeletedProjectStaff:  (state, id) => {
        let projectStaffCopy = state.projectStaff.filter( staff => staff.id !== id)
        state.projectStaff = null
        state.projectStaff = projectStaffCopy
    },

    setUsersDropdown: (state, data) => state.usersDropdown = data,
    setRolesDropdown: (state, data) => state.rolesDropdown = data,

    setStaffScheme:(state, data) => state.staffScheme = data,

    setNewStaffParams: (state, params) => {
        let staff = {
            ...state.newStaffParams,
            ...params,
        }
        state.newStaffParams = staff
    },

    setNewStaffArray: (state, data) => {
        if(data) return state.newStaffArray.push(data)
        state.newStaffArray = []
    },

    setDeletedProjectStaffArray: (state, id) => {
        if(state.newStaffArray.length !== 0) {
            let newStaffArrayCopy = state.newStaffArray.filter( staff => staff.id !== id)
            state.newStaffArray = null
            state.newStaffArray = newStaffArrayCopy
        }
    },

    setEditStaffScheme: (state, editStaffScheme) => state.editStaffScheme = editStaffScheme,
    setStaffError: (state, staffError) => state.staffError = staffError,
}

/* * * ACTIONS * * */
const actions = {
 //All Staff
    async fetchProjectStaff({commit},id){
        try {
            const res = await  axios.get(`/projects/${id}/staff.json`)
            if(res.data.error) throw res.data.message
            commit('setProjectStaff', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

//Staff User & Roles Dropdowns

    async fetchStaffScheme({commit}, {id, org_id}) {
        try {
            const res = await axios.get(`/projects/${id}/project_staffs/new.json` , { params: { org_id: `${org_id}` } })

            if(res.data.error) throw res.data.message;
            commit('setUsersDropdown', res.data[0]);
            commit('setRolesDropdown', res.data.slice(-1).pop());
            commit('setStaffScheme', res.data.slice(-1).pop().roles);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'fetch', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//POST Add new Staff
    async createStaff({commit, dispatch}, {project_staff, org_id = null}){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_staff,
            };

            const res = await axios.post(`/project_staffs.json`, params, { headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'added', text: 'Project Staff'});
            commit('setNewStaffArray', res.data);
            dispatch('fetchProjectStaff', project_staff.project_id )
            dispatch('fetchStaffScheme', {id: project_staff.project_id, org_id: org_id});
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    setNewStaffParams({commit}, params){
        commit('setNewStaffParams', params);
    },

    setRolesDropdown({commit}, params){
        const newRoles = {
            roles:[...params]
        }
        commit('setRolesDropdown', newRoles);
    },

//PUT Edit Staff Scheme

    fetchEditStaffScheme({commit}, id) {
        axios.get(`project_staffs/${id}/edit.json`).then(res => {
            commit('setEditStaffScheme', res.data);
            commit('setStaffScheme', res.data[1].roles);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateStaff({commit, dispatch}, {project_staff, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_staff,
            };

            const res = await axios.put(`/project_staffs/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'updated', text: 'Project Staff'});
            dispatch('fetchProjectStaff', project_staff.project_id )
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//DELETE delete staff
    async deleteStaff({commit}, id) {
        try {

            const res = await axios.delete(`/project_staffs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'Project Staff', pageType: 'deleteStaff'});
            commit('setDeletedProjectStaffArray', id);
            commit('setDeletedProjectStaff', id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}
