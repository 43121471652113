import axios from 'axios';

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    projectParticipation: null,
    participationScheme: null,

    participationProperties: {},
    orgContacts: [],
    participationResponse: null,
    reducedCostCalculator: [],
    adjustReducedCostOptions: null,
}
const getters = {
    projectParticipation: state => state.projectParticipation,
    participationScheme: state => state.participationScheme,

    participationProperties: state => state.participationProperties,
    orgContacts: state => state.orgContacts,
    participationResponse: state => state.participationResponse,
    reducedCostCalculator: state => state.reducedCostCalculator,
    adjustReducedCostOptions: state => state.adjustReducedCostOptions
}

const mutations = {
    setProjectParticipation: (state, data) => state.projectParticipation = data,
    setParticipationScheme: (state, data) => state.participationScheme = data,

    setParticipationProperties: (state, params) => {
        let participation = {
            ...state.participationProperties,
            ...params,
        }
        state.participationProperties = participation
    },
    setOrgContacts: (state, data) => state.orgContacts = data,
    setParticipationResponse: (state, data) => {
        state.participationResponse = data
    },
    setReducedCostCalculator: (state, data) => {
        let newDataArray = []
        for(const partner of data.reduced_cost_partners) {
            const params = {}
            params["org_id"] = partner.org_id
            params["org"] = partner.org_initials
            params["cost"] = null
            for(const partnerFees of data.fees) {
                if(partner.org_id === partnerFees.org_id){
                    params["cost"] = partnerFees.cost
                }
            }
            newDataArray.push(params)
        }
        state.reducedCostCalculator = newDataArray
    },
    setAdjustReducedCostOptions: (state, data) => state.adjustReducedCostOptions = data,
    setUpdateReducedCostCalculator: (state, array) => {
        state.reducedCostCalculator = []
        state.reducedCostCalculator = array
    },
}

const actions = {
    fetchProjectParticipation({commit}, id) {
        axios.get(`/projects/${id}/participation.json`).then(res => {
            commit('setProjectParticipation', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchProjectParticipationYear({commit}, {id, year}) {
        axios.get(`/projects/${id}/participation.json?year=${year}`).then(res => {
            commit('setProjectParticipation', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


    fetchNewParticipationScheme({commit}, id) {
        axios.get(`/batches/${id}/batch_org_participations/new.json`).then(res => {
            commit('setParticipationScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    setParticipationProperties({commit}, params){
        commit('setParticipationProperties', params);
    },

    fetchOrgContacts({commit}, id){
        axios.get(`/orgs/${id}/project_contacts.json`).then(res => {
            commit('setOrgContacts', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createParticipation({commit, dispatch}, {batch_org_participation, project_id}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation,
            };

            const res = await  axios.post(`/batch_org_participations.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: `${batch_org_participation.pending ? 'submitted join request' : 'added'}`, text: `${batch_org_participation.pending ? '' : 'a new participation to the project'}` });
            commit('setProjectParticipation', res.data.data);
           /* dispatch('fetchProject', project_id)*/

            return Promise.resolve(res.data.created);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },


    async removeParticipation({commit}, {id, isDeclineRequest, isFromRequestParticipation = false}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: {
                    id: id,
                },
                decline: isDeclineRequest
            };

            const res = await  axios.post(`/batch_org_participations/remove.json`, params, { headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message


            if(!isFromRequestParticipation) {
                commit('setAlertResponse', { type: 'successful', action: "removed", text: 'participation from the project'});
                commit('setProjectParticipation', res.data);
            }

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },

    async fetchEditParticipationScheme({commit}, id){

        try {
            const res = await  axios.get(`/batch_org_participations/${id}/edit.json`)

            if(res.data.error) throw res.data.message;
            commit('setParticipationScheme', res.data);

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    async updateParticipation({commit, dispatch}, {participant, id, project_id, replace}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: participant,
                id:id,
            };

            const _rep = replace ? '/replace' : '';

            const res = await axios.put(`/batch_org_participations/${id}${_rep}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;

            dispatch('fetchProject', project_id)
            dispatch('fetchProjectParticipation', project_id)
            commit('setAlertResponse', { type: 'successful', action: "updated", text: 'participation'});

            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async deleteParticipationRecord({commit}, id){

        try {
            const res = await axios.delete(`/batch_org_participations/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    batch_org_participation: {
                        id:id,
                    },
                }
            });

            if(res.data.error) throw res.data.message;

            commit('setAlertResponse', { type: 'successful', action: "deleted", text: 'participation record'});
            commit('setProjectParticipation', res.data);

            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject()
        }

    },

    fetchReducedCostCalculator({commit}, id){
        axios.get(`/projects/${id}/reduced_cost_contribution.json`).then(res => {
            commit('setReducedCostCalculator', res.data);
            commit('setAdjustReducedCostOptions', res.data.fees);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    resetParticipationResponse({commit}){
        commit('setParticipationResponse', null);
    },

    async acceptParticipation({commit}, id){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: {
                    id: id,
                },
            };

            const res = await axios.post(`/batch_org_participations/accept.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;

            commit('setAlertResponse', { type: 'successful', action: "accepted", text: 'project participation'});
            commit('setProjectParticipation', res.data);

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },

    async deferParticipation({commit}, {comment, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: {
                    id: id,
                    comment: comment
                }
            };

            const res = await axios.post(`/batch_org_participations/defer.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.date.message;
            
            commit('setAlertResponse', { type: 'successful', action: "defer", text: 'participation request'});
            commit('setProjectParticipation', res.data);

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },

    async leaveRequest({commit}, {id, completely}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: {
                    id: id,
                    completely: completely,
                }
            };

            const res = await axios.post(`/batch_org_participations/request_leave.json`, params, {headers: {'Content-Type': 'application/json'}});

            if(res.data.error) throw res.data.message;

            commit('setAlertResponse', { type: 'successful', action: "requested to leave", text: ' the project'});
            commit('setProjectParticipation', res.data);

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },

    async declineLeaveRequest({commit},{id, completely}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_org_participation: {
                    id: id,
                    completely: completely,
                }
            };

            const res = await axios.post(`/batch_org_participations/decline_leave.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;

            commit('setAlertResponse', { type: 'successful', action: "declined", text: 'leave request'});
            commit('setProjectParticipation', res.data);

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },


    async markInterest({commit, dispatch}, project_id){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_id: project_id,
            };

            const res = await axios.post(`/batch_org_participations/interest.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;

            commit('setAlertResponse', { type: 'successful', action: "marked interest", text: ''});
            dispatch('fetchProjectParticipation', project_id)

            return Promise.resolve();
            
        } catch(error) {
                commit('setAlertResponse', { type: 'error', text: error})
                return Promise.reject();
        }
    },

    async removeInterest({commit}, project_id){
        try {
            const res = await  axios.delete(`/batch_org_participations/${project_id}/interest.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            });

            if(res.data.error) throw res.data.message;

            commit('setProjectParticipation', res.data);
            commit('setAlertResponse', {type: 'successful', action: 'removed the interest', text: 'from Project'});

            return Promise.resolve();
        }
        catch(error) {
            commit('setAlertResponse', { type: 'error', text: error})
            return Promise.reject();
        }
    },


}

export default {
    state,
    getters,
    mutations,
    actions,
}
