import axios from 'axios';

/* STATE * * * * * * * * * * * * * * * * * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    khTabs: null,
    khTabScheme: null,
    tableOfContent: null,
    tab: null,
    pageContent: null,
    chartContent: null,
    pageContentScheme: null,
    bookHistory: null,

}

/* GETTERS * * * * * * * * * * * * * * * * * * */
const getters = {
    khTabs: state => state.khTabs,
    khTabScheme: state => state.khTabScheme,
    tableOfContent: state => state.tableOfContent,
    tab: state => state.tab,
    pageContent: state => state.pageContent,
    chartContent: state => state.chartContent,
    pageContentScheme: state => state.pageContentScheme,
    bookHistory: state => state.bookHistory,
}

/* MUTATIONS * * * * * * * * * * * * * * * * * * */
const mutations = {
    setKhTabs: (state, data) =>   state.khTabs = data,
    setKhTabScheme: (state, data) =>   state.khTabScheme = data,
    setTableOfContent: (state, data) =>   state.tableOfContent = data,
    setTab: (state, data) =>   state.tab = data,
    setPageContent: (state, data) =>   state.pageContent = data,
    setChartContent: (state, data) =>   state.chartContent = data,
    setPageContentScheme: (state, data) =>   state.pageContentScheme = data,
    setBookHistory: (state, data) =>   state.bookHistory = data,
}



/* ACTIONS * * * * * * * * * * * * * * * * * * */
const actions = {

    /*   TABS       */

    async fetchKhTabs({commit}) {
        try {
            const res = await axios.get(`/knowledgehub/tabs.json`)
            if(res.status !== 200) return
            if(res.data.error) throw res.data.message
            commit('setKhTabs', res.data.tabs);
            return Promise.resolve(res.data);
        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async fetchNewKhTabScheme({commit}) {
        try {
            const res = await axios.get(`/knowledgehub/tabs/new.json`);
            if(res.data.error) throw res.data.message
            commit('setKhTabScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async createKhTab({ commit, dispatch }, tabContent) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                tab: tabContent
            };

            const res = await axios.post(`/knowledgehub/tabs.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'created', text: `a new tb`, data: res.data});
            dispatch('fetchKhTabs');
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchEditKhTabScheme({commit}, id) {
        try {
            const res = await axios.get(`/knowledgehub/tabs/${id}/edit.json`);
            if(res.data.error) throw res.data.message
            commit('setKhTabScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async updateKhTab({ commit, dispatch }, {id, tabContent}) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: id,
                tab: tabContent
            };

            const res = await axios.put(`/knowledgehub/tabs/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'created', text: `a new tb`, data: res.data});
            commit('setKhTabs', res.data.tabs);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deleteKhTab({ commit, dispatch }, id){
        try {
            const res = await  axios.delete(`/knowledgehub/tabs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: `from Tabs`});
            commit('setKhTabs', res.data.tabs);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    /* TABLE OF CONTENT */

    async fetchTab({commit}, tab_id) {
        try {
            const res = await axios.get(`/knowledgehub/tabs/${tab_id}.json`)
            if(res.data.error) throw res.data.message
            commit('setTab', res.data);
            return Promise.resolve(res.data);
        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async fetchBookTable({commit}, book_id) {
        try {
            const res = await axios.get(`/knowledgehub/books/content_table/${book_id}.json`)
            if(res.data.error) throw res.data.message
            commit('setTableOfContent', res.data);
            return Promise.resolve(res.data);
        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    /* KH Content Book/ Chapter/ Page */

    async fetchTabContent({commit}, { id, type }) {
        try {
            const res = await axios.get(`/knowledgehub/${type}/${id}.json`)
            if(res.data.error) throw res.data.message
            commit('setPageContent', res.data);
            return Promise.resolve(res.data);
        } catch (error) {
            commit('setAlertResponse', { type: 'error', action: '', text: error, timeout: 5000 });
            return Promise.reject();
        }
    },

    async fetchNewBookScheme({commit}, tab_id) {
        try {
            const res = await axios.get(`/knowledgehub/tabs/${tab_id}/books/new.json`);
            if(res.data.error) throw res.data.message
            commit('setPageContentScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchNewChapterScheme({commit}, book_id) {
        try {
            const res = await axios.get(`/knowledgehub/books/${book_id}/chapters/new.json`);
            if(res.data.error) throw res.data.message
            commit('setPageContentScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchNewBookPageScheme({commit}, book_id ) {
        try {
            const res = await axios.get(`/knowledgehub/books/${book_id}/pages/new.json`);
            if(res.data.error) throw res.data.message
            commit('setPageContentScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchNewChapterPageScheme({commit}, chapter_id ) {
        try {
            const res = await axios.get(`/knowledgehub/chapters/${chapter_id}/pages/new.json`);
            if(res.data.error) throw res.data.message
            commit('setPageContentScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async createPageContent({ commit, dispatch }, {type, pageContent, tab_id}) {
        try {

            const typeParams = {}
            typeParams[`${type.slice(0, -1)}`] = pageContent

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                ...typeParams
            };

            const res = await axios.post(`/knowledgehub/${type}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'created', text: `a new ${type.slice(0, -1)}`, data: res.data});
            if(type !== 'chapters') commit('setPageContent', res.data);
            dispatch('fetchTab', tab_id);
            dispatch('fetchKhTabs');
            dispatch('fetchBookTable', res.data.location.books.id);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchEditPageContentScheme({commit}, {id, type, requestType = 'edit'}) {
        try {
            const res = await axios.get(`/knowledgehub/${type}/${id}/${requestType}.json`);
            if(res.data.error) throw res.data.message
            commit('setPageContentScheme', res.data);
            return Promise.resolve(res.data);
        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async updatePageContent({ commit, dispatch }, { id, type, pageContent, tab_id, movePage = false}) {
        try {
            const typeParams = type === 'books' ? { book: pageContent } : type === 'chapters' ? { chapter: pageContent } : { page: pageContent }

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: id,
                ...typeParams
            };

            const actionText = movePage ? 'moved' : 'updated'

            const res = await axios.put(`/knowledgehub/${type}/${id}.json`, params, {headers: {'Content-Type': 'application/json'}});
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: actionText , text: `the ${type.slice(0, -1)} content`});
            if(type !== 'chapters') commit('setPageContent', res.data);
            if(!movePage) dispatch('fetchTab', tab_id);
            dispatch('fetchBookTable', res.data.location.books.id);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deletePageContent({ commit, dispatch }, { id, type }){
        try {
            const res = await  axios.delete(`/knowledgehub/${type}/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: `a ${type}`});
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    /*  BOOKMARKS         */

    async bookmarkPageContent({ commit, dispatch }, {id, type }) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: id,
                markable_type: `Knowledgehub::${type.slice(0, -1)}`,
            };

            const res = await axios.post(`/knowledgehub/add_bookmark/${type}/${id}.json`, params ,{ headers: {'Content-Type': 'application/json'}});
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'bookmarked', text: `the ${type.slice(0, -1)}`, data: res.data});
            dispatch('fetchTabContent', {id: id, type: type });
            dispatch('fetchKhTabs');
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deleteBookmarkPageContent({ commit, dispatch }, { id, type }){
        try {
            const res = await  axios.delete(`/knowledgehub/remove_bookmark/${type}/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                    markable_type: `Knowledgehub::${type.slice(0, -1)}`,
                }
            });

            if(res.data.error) throw res.data.message
            dispatch('fetchTabContent', { id: id, type: type });
            dispatch('fetchKhTabs');
            commit('setAlertResponse', {type: 'successful', action: 'removed bookmark', text: `from ${type}`});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async fetchChart({commit}, id) {
        try {
            const res = await axios.get(`/knowledgehub/html_templates/${id}`);
            if(res.data.error) throw res.data.message
            commit('setChartContent', res.data);
            return Promise.resolve(res.data);
        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchBookHistory({commit}, id) {
        try {
            const res = await axios.get(`/knowledgehub/books/${id}/history.json`);
            if(res.data.error) throw res.data.message
            commit('setBookHistory', res.data);
            return Promise.resolve(res.data);
        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}